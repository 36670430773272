var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "padding-bottom": "50px", height: "100vh" } },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("span", { staticClass: "title" }, [_vm._v("支付配置")]),
          _vm._t("center"),
          _c("i", {
            staticClass: "rs-icon-close",
            on: { click: _vm.jumpBack },
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          staticStyle: {
            "overflow-y": "scroll",
            height: "calc(100vh - 120px)",
          },
        },
        [
          _c(
            "rs-form",
            {
              ref: "payForm",
              staticStyle: { width: "70%" },
              attrs: {
                model: _vm.formData,
                "label-width": "140px",
                "show-message": false,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "rs-form-item",
                { attrs: { label: "项目：", prop: "mallCode" } },
                [
                  _c("rs-select-mall", {
                    ref: "mallRef",
                    attrs: {
                      host: "/api/user",
                      clearable: false,
                      mustFlag: false,
                      disabled: _vm.type == "edit",
                    },
                    model: {
                      value: _vm.formData.mallCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "mallCode", $$v)
                      },
                      expression: "formData.mallCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "rs-form-item",
                { attrs: { label: "APPID：", prop: "appId" } },
                [
                  _c("rs-input", {
                    attrs: { maxlength: "128", placeholder: "请输入APPID" },
                    model: {
                      value: _vm.formData.appId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "appId", $$v)
                      },
                      expression: "formData.appId",
                    },
                  }),
                  _c("p", { staticClass: "tip" }, [
                    _c("i", { staticClass: "rs-icon-info" }),
                    _c("i", [
                      _vm._v(
                        "商户注册具有支付权限的公众号成功后即可获得（或微信开放平台审核通过的应用）"
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "rs-form-item",
                { attrs: { label: "直联/间联标识：", prop: "connectionType" } },
                [
                  _c(
                    "rs-select",
                    {
                      model: {
                        value: _vm.formData.connectionType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "connectionType", $$v)
                        },
                        expression: "formData.connectionType",
                      },
                    },
                    [
                      _c("rs-option", {
                        attrs: { label: "直联", value: "DIRECT" },
                      }),
                      _c("rs-option", {
                        attrs: { label: "间联", value: "INDIRECT" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.formData.defaultMall
                ? _c(
                    "rs-form-item",
                    { attrs: { label: "主体公司：", prop: "companyCode" } },
                    [
                      _c(
                        "rs-select",
                        {
                          on: { change: _vm.changeCompany },
                          model: {
                            value: _vm.formData.companyCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "companyCode", $$v)
                            },
                            expression: "formData.companyCode",
                          },
                        },
                        _vm._l(_vm.companyList, function (item, index) {
                          return _c("rs-option", {
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "rs-form-item",
                { attrs: { label: "AppSecret：", prop: "appSecret" } },
                [
                  _c("rs-input", {
                    attrs: { maxlength: "128", placeholder: "请输入AppSecret" },
                    model: {
                      value: _vm.formData.appSecret,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "appSecret", $$v)
                      },
                      expression: "formData.appSecret",
                    },
                  }),
                  _c("p", { staticClass: "tip" }, [
                    _c("i", { staticClass: "rs-icon-info" }),
                    _c("i", [
                      _vm._v(
                        "AppSecret是APPID对应的接口密码，用于获取接口调用凭证access_token时使用"
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "rs-form-item",
                { attrs: { label: "API密钥：", prop: "apiKey" } },
                [
                  _c("rs-input", {
                    attrs: { maxlength: "128", placeholder: "请输入API密钥" },
                    model: {
                      value: _vm.formData.apiKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "apiKey", $$v)
                      },
                      expression: "formData.apiKey",
                    },
                  }),
                  _c("p", { staticClass: "tip" }, [
                    _c("i", { staticClass: "rs-icon-info" }),
                    _c("i", [
                      _vm._v(
                        "API密钥，交易过程生成签名的密钥，仅保留在商户系统和微信支付后台，不会在网络中传播"
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "rs-form-item",
                { attrs: { label: "merchant_id：", prop: "merchantId" } },
                [
                  _c("rs-input", {
                    attrs: {
                      maxlength: "128",
                      placeholder: "请输入merchant_id",
                    },
                    model: {
                      value: _vm.formData.merchantId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "merchantId", $$v)
                      },
                      expression: "formData.merchantId",
                    },
                  }),
                  _c(
                    "rs-checkbox",
                    {
                      model: {
                        value: _vm.formData.defaultMall,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "defaultMall", $$v)
                        },
                        expression: "formData.defaultMall",
                      },
                    },
                    [_vm._v("在线商城商户号")]
                  ),
                  _c("p", { staticClass: "tip" }, [
                    _c("i", { staticClass: "rs-icon-info" }),
                    _c("i", [
                      _vm._v(
                        "微信支付商户号，商户申请微信支付后，由微信支付分配的商户收款账号"
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "rs-form-item",
                { attrs: { label: "停车回调地址：" } },
                [
                  _c("rs-input", {
                    attrs: {
                      maxlength: "128",
                      placeholder: "请输入停车回调地址",
                      disabled: "",
                    },
                    model: {
                      value: _vm.formData.parkNotifyUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "parkNotifyUrl", $$v)
                      },
                      expression: "formData.parkNotifyUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "rs-form-item",
                { attrs: { label: "商户API证书：" } },
                [
                  _c(
                    "rs-upload",
                    {
                      attrs: {
                        action: _vm.uploadUrl,
                        "before-upload": _vm.beforeAvatarUpload,
                        "on-success": _vm.fileUploadSuccess,
                        "on-remove": _vm.handleRemove,
                        limit: _vm.limit,
                        "file-list": _vm.attachmentList,
                      },
                    },
                    [
                      _c(
                        "rs-button",
                        {
                          attrs: {
                            size: "small",
                            icon: "iconzendai-icon-shangchuanwenjian",
                          },
                        },
                        [_vm._v("上传文件")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "rs-upload__tip",
                          staticStyle: { "margin-left": "10px" },
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [_vm._v("支持扩展名：.p12")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "edit-form button-box",
          staticStyle: { "text-align": "right" },
        },
        [
          _c(
            "rs-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.jumpBack(false)
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "rs-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }