import { render, staticRenderFns } from "./paySettingDetail.vue?vue&type=template&id=d41d046e&scoped=true"
import script from "./paySettingDetail.vue?vue&type=script&lang=js"
export * from "./paySettingDetail.vue?vue&type=script&lang=js"
import style0 from "./paySettingDetail.vue?vue&type=style&index=0&id=d41d046e&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d41d046e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\西迪港\\项目\\web\\user-h5-xdg\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d41d046e')) {
      api.createRecord('d41d046e', component.options)
    } else {
      api.reload('d41d046e', component.options)
    }
    module.hot.accept("./paySettingDetail.vue?vue&type=template&id=d41d046e&scoped=true", function () {
      api.rerender('d41d046e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/initialSetting/components/paySettingDetail.vue"
export default component.exports