<template>
  <div style="padding-bottom: 50px;height: 100vh;">
    <div class="header">
      <span class="title">支付配置</span>
      <slot name="center"></slot>
      <i class="rs-icon-close" @click="jumpBack"></i>
    </div>
    <div style="overflow-y: scroll;height: calc(100vh - 120px)">
      <rs-form :model="formData" label-width="140px" style="width: 70%" :show-message="false" :rules="rules" ref="payForm">
        <rs-form-item label="项目：" prop="mallCode">
          <rs-select-mall
            :host="'/api/user'"
            v-model="formData.mallCode"
            :clearable="false"
            :mustFlag="false"
            ref="mallRef"
            :disabled="type == 'edit'"
          ></rs-select-mall>
        </rs-form-item>
        <rs-form-item label="APPID：" prop="appId">
          <rs-input v-model="formData.appId" maxlength="128" placeholder="请输入APPID"></rs-input>
          <p class="tip">
            <i class="rs-icon-info"></i>
            <i>商户注册具有支付权限的公众号成功后即可获得（或微信开放平台审核通过的应用）</i>
          </p>
        </rs-form-item>
        <rs-form-item label="直联/间联标识：" prop="connectionType">
          <rs-select v-model="formData.connectionType">
            <rs-option label="直联" value="DIRECT"></rs-option>
            <rs-option label="间联" value="INDIRECT"></rs-option>
          </rs-select>
        </rs-form-item>

        <rs-form-item label="主体公司：" prop="companyCode" v-if="!formData.defaultMall">
          <rs-select v-model="formData.companyCode" @change="changeCompany">
            <rs-option v-for="(item,index) in companyList" :label="item.label" :value="item.value" ></rs-option>
          </rs-select>
        </rs-form-item>
        <rs-form-item label="AppSecret：" prop="appSecret">
          <rs-input v-model="formData.appSecret" maxlength="128" placeholder="请输入AppSecret"></rs-input>
          <p class="tip">
            <i class="rs-icon-info"></i>
            <i>AppSecret是APPID对应的接口密码，用于获取接口调用凭证access_token时使用</i>
          </p>
        </rs-form-item>
        <rs-form-item label="API密钥：" prop="apiKey">
          <rs-input v-model="formData.apiKey" maxlength="128" placeholder="请输入API密钥"></rs-input>
          <p class="tip">
            <i class="rs-icon-info"></i>
            <i>API密钥，交易过程生成签名的密钥，仅保留在商户系统和微信支付后台，不会在网络中传播</i>
          </p>
        </rs-form-item>
        <rs-form-item label="merchant_id：" prop="merchantId">
          <rs-input v-model="formData.merchantId" maxlength="128" placeholder="请输入merchant_id"></rs-input>
          <rs-checkbox v-model="formData.defaultMall">在线商城商户号</rs-checkbox>
          <p class="tip">
            <i class="rs-icon-info"></i>
            <i>微信支付商户号，商户申请微信支付后，由微信支付分配的商户收款账号</i>
          </p>
        </rs-form-item>
        <rs-form-item label="停车回调地址：">
          <rs-input v-model="formData.parkNotifyUrl" maxlength="128" placeholder="请输入停车回调地址" disabled></rs-input>
        </rs-form-item>
        <rs-form-item label="商户API证书：">
          <rs-upload
            :action="uploadUrl"
            :before-upload="beforeAvatarUpload"
            :on-success="fileUploadSuccess"
            :on-remove="handleRemove"
            :limit="limit"
            :file-list="attachmentList"
          >
            <rs-button size="small" icon="iconzendai-icon-shangchuanwenjian">上传文件</rs-button>
            <span slot="tip" class="rs-upload__tip" style="margin-left: 10px">支持扩展名：.p12</span>
          </rs-upload>
        </rs-form-item>
      </rs-form>
    </div>
    <div class="edit-form button-box" style="text-align: right">
      <rs-button size="small" @click="jumpBack(false)">取消</rs-button>
      <rs-button size="small" type="primary" @click="save">保存</rs-button>
    </div>
  </div>
</template>
<script>
import Api from '@/api/imgSetter.js'
import headerTitle from '@/components/header'
import Bus from '@/utils/eventBus'
export default {
  components: { headerTitle },
  data() {
    return {
      companyList:[],
      formData: {
          payType:1,
          defaultMall:false,
          companyCode:'',
          connectionType:''

      },
      limit: 1,
      rules: {
        mallCode: [{ required: true, message: '请先选择项目', trigger: 'blur' }],
        appId: [{ required: true, message: '请输入APPID', trigger: 'blur' }],
        connectionType: [{ required: true, message: '请选择直联/间联标识', trigger: 'change' }],
        companyCode: [{ required: true, message: '请选择主题公司', trigger: 'change' }],
        appSecret: [{ required: true, message: '请输入AppSecret', trigger: 'blur' }],
        apiKey: [{ required: true, message: '请输入API密钥', trigger: 'blur' }],
        merchantId: [{ required: true, message: '请输入merchant_id', trigger: 'blur' }]
      },
      uploadUrl: '',
      fileList: [],
      attachmentList:[],
      type: '',
      rowId: '',
      uploadData: {},
      fileObj: {}
    }
  },
  created() {
    this.uploadUrl = Api.uploadUrl()

    this.type = this.$route.query.type
    if (this.type == 'edit') {
      this.rowId = this.$route.query.rowId
    //   this.uploadData = { id: this.rowId }
      this.getDetail()
    }
  },
  watch:{
    'formData.mallCode':function (value){
      this.getCompanys(value);
    }
  },
  methods: {
    changeCompany(companyCode){
      this.formData.companyName = this.companyList.filter((item)=>{
        return item.value == companyCode
      })[0].label
    },
    //获取项目下的主体公司
    getCompanys(mallCode) {
      return Api
          .queryCompanyListByType({
            mallCode,
            pageNum: 1,
            pageSize: 999
          })
          .then(res => {
           this.companyList = res.data.data.records.map(item => {
              return {
                label: item.companyName,
                value: item.companyCode
              }
            })
          })
    },
    jumpBack(flag) {
      this.$router.push({name:'initialSetting',params:{from:'pay'}})
    },
    getDetail() {
      let params = { id: this.rowId }
      Api.findDetailById(params).then((res) => {
        if (res.data.code == 200) {
          this.formData = { ...res.data.data }
          if(this.formData.certFileName){
               this.attachmentList.push({name:this.formData.certFileName,url:''})
          console.log( this.attachmentList)
          }

        }
      })
    },
    handleRemove(fileList, index) {
        this.formData.certFileName=""
        this.attachmentList =[];
        this.fileList=[]

    },
    fileUploadSuccess(res, file, fileList) {
      let formdata = new FormData()
      formdata.append('file', file.raw)
      this.formData.certFileName = file.name
      this.fileList.push(file)
      this.fileObj = formdata
    },
    beforeAvatarUpload() {},
    save() {
      this.$refs.payForm.validate((valid) => {
        if (valid) {
            let params = {}
         if(this.fileList.length){
            for (var key in this.formData) {
            this.fileObj.delete(key, this.formData[key])
            this.fileObj.append(key, this.formData[key])
          }
            params=this.fileObj
         }else{
            var formData = new FormData()
            for (var key in this.formData) {
                formData.delete(key, this.formData[key])
                formData.append(key, this.formData[key])
            }
             params=formData
         }
          Api.saveAccountConfig(params).then((res) => {
              if(res.data.code == 200){
                  this.$message.success('保存成功');
                  this.jumpBack()
              }
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  height: 44px;
  border-bottom: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 30px;

  .title {
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    line-height: 22px;
  }

  .rs-icon-close {
    font-size: 20px;
    color: #999999;
    cursor: pointer;
  }
}
/deep/ .tip {
  line-height: 1.5;
  color: #747070;
  font-size: 12px;
  margin-top: 5px;
}
</style>
